<template>
  <div class="wrap" v-loading="loading">
    <el-form ref="form" :model="fillRuleForm" :rules="rules" label-width="96px">
      <topOperatingButton
        :isExamineBtn="true"
        :showAudit="false"
        :isStopBtn="true"
        @submitForm="submitForm(false)"
        @addBill="submitForm(true)"
        @auditBill="auditBill"
        @getQuit="$router.go(-1)"
        @isStop="getEnable"
        id="topOperatingButton"
      >
      </topOperatingButton>
      <cardTitleCom
        cardTitle="基本信息"
        id="basicInformation"
        :billStatus="fillRuleForm.billStatus"
      >
        <template slot="cardContent">
          <div class="x-f marT10">
            <el-form-item label="方案编号" prop="billNo">
              <el-input
                class="inputWidth"
                size="mini"
                :disabled="true"
                v-model="fillRuleForm.billNo"
                placeholder="方案编号"
              ></el-input>
            </el-form-item>
            <el-form-item label="方案名称" prop="billRuleName">
              <el-input
                class="inputWidth"
                size="mini"
                :disabled="disabled"
                v-model="fillRuleForm.billRuleName"
                placeholder="方案名称(1-40字符)"
                maxlength="40"
              ></el-input>
            </el-form-item>
            <el-form-item label="有效时间" prop="datetime">
              <el-date-picker
                size="mini"
                v-model="fillRuleForm.datetime"
                type="datetimerange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']"
                value-format="yyyy-MM-dd HH:mm:ss"
                :picker-options="pickerOptions"
                :disabled="disabled"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="方案备注" prop="billRemark">
              <el-input
                size="mini"
                class="inputWidth"
                :disabled="disabled"
                v-model="fillRuleForm.billRemark"
                type="textarea"
                placeholder="备注长度介于 1 和 80 字符之间"
                maxlength="80"
                :autosize="{ minRows: 1, maxRows: 1 }"
              />
            </el-form-item>
          </div>
        </template>
      </cardTitleCom>
      <cardTitleCom cardTitle="充值赠送规则">
        <template slot="cardContent">
          <div class="giveRule">
            <div class="multiplyTitle">
              <!-- <el-checkbox
                v-model="fillRuleForm.isMultiply"
                :disabled="disabled"
              />
              <span class="checkboxTitle">
                倍增（启用倍增后，优先匹配更高金额的赠送规则，高金额未达标时，匹配低一级金额的赠送规则进行倍增）
              </span> -->
            </div>
            <div class="fillRuleListTable">
              <div
                v-for="(item, index) in fillRuleForm.largessItems"
                :key="index"
                class="fillRuleList"
                :class="{ focusfillRuleList: clickItem == index }"
                @click="focusRuleList(index)"
              >
                <div class="fillRuleListMoney">
                  <span> 充值金额：{{ item.fillMoney }}元</span>
                  <i class="el-icon-delete" @click="deleteRuleList(index)"></i>
                </div>
                <div v-show="item.largessMoney">赠送金额：{{ item.largessMoney }}元</div>
                <div v-show="item.largessScore">赠送积分:{{ item.largessScore }} 分</div>
              </div>
              <div class="fillRuleList" @click="getRuleListAdd">
                <span class="fillRuleListAdd">+添加</span>
              </div>
            </div>
            <div>
              <div class="RuleListdetail">
                <el-form-item
                  label="充值金额:"
                  :prop="`largessItems[${clickItem}].fillMoney`"
                  :rules="rules[`largessItems.fillMoney`]"
                >
                  <el-input
                    size="mini"
                    class="fillRuleinput"
                    v-model="fillRuleForm.largessItems[clickItem].fillMoney"
                    @input="
                      limitInputlength(
                        fillRuleForm.largessItems[clickItem].fillMoney,
                        fillRuleForm.largessItems[clickItem],
                        'fillMoney',
                        true
                      )
                    "
                    :max="9999999"
                    :disabled="disabled"
                  ></el-input>
                  <span>元</span>
                </el-form-item>
              </div>
              <div class="RuleListdetail">
                <el-checkbox
                  v-model="fillRuleForm.largessItems[clickItem].isLargessMoney"
                  :disabled="disabled"
                />
                <span class="checkboxTitle">赠送金额</span>
                <div
                  v-if="fillRuleForm.largessItems[clickItem].isLargessMoney"
                  class="x-f"
                >
                  <el-radio
                    v-model="fillRuleForm.largessItems[clickItem].largessMoneyMode"
                    :label="0"
                    class="giveMoneyRadio"
                    :disabled="disabled"
                    >自定义</el-radio
                  >
                  <el-form-item
                    label-width="0px"
                    :prop="
                      fillRuleForm.largessItems[clickItem].largessMoneyMode == 0
                        ? `largessItems[${clickItem}].largessMoney`
                        : ''
                    "
                    :rules="rules[`largessItems.largessMoney`]"
                  >
                    <el-input
                      size="mini"
                      class="fillRuleinputMoney"
                      v-model="fillRuleForm.largessItems[clickItem].largessMoney"
                      :disabled="
                        disabled ||
                        fillRuleForm.largessItems[clickItem].largessMoneyMode == 1
                      "
                      @input="
                        limitInputlength(
                          fillRuleForm.largessItems[clickItem].largessMoney,
                          fillRuleForm.largessItems[clickItem],
                          'largessMoney',
                          true
                        )
                      "
                      :max="9999999"
                    ></el-input>
                  </el-form-item>
                  <span>元</span>
                  <el-radio
                    :disabled="disabled"
                    v-model="fillRuleForm.largessItems[clickItem].largessMoneyMode"
                    :label="1"
                    class="giveMoneyRadio"
                    >充多少送多少</el-radio
                  >
                </div>
              </div>
              <div class="RuleListdetail">
                <el-checkbox
                  v-model="fillRuleForm.largessItems[clickItem].isLargessCoupon"
                  :disabled="disabled"
                />
                <span class="checkboxTitle">赠送优惠券</span>
                <el-button
                  v-show="fillRuleForm.largessItems[clickItem].isLargessCoupon"
                  size="mini"
                  @click="handleEvent('openCouponDialog')"
                  :disabled="disabled"
                  >选择优惠券</el-button
                >
              </div>
              <div v-show="fillRuleForm.largessItems[clickItem].isLargessCoupon">
                <!-- 可编辑表格 -->
                <EditTable :options="couponOptions" />
              </div>
              <div class="RuleListdetail">
                <el-checkbox
                  v-model="fillRuleForm.largessItems[clickItem].isLargessGoods"
                  :disabled="disabled"
                />
                <span class="checkboxTitle">赠送商品</span>
                <el-button
                  v-show="fillRuleForm.largessItems[clickItem].isLargessGoods"
                  size="mini"
                  @click="handleEvent('openGoodsDialog')"
                  :disabled="disabled"
                  >选择商品</el-button
                >
              </div>
              <div v-show="fillRuleForm.largessItems[clickItem].isLargessGoods">
                <el-form
                  ref="goodsForm"
                  :model="goodsOptions"
                  :rules="rules"
                  label-width="96px"
                >
                  <!-- 可编辑表格 -->
                  <EditTable :options="goodsOptions" />
                </el-form>
              </div>
              <div class="RuleListdetail">
                <el-checkbox
                  v-model="fillRuleForm.largessItems[clickItem].isLargessScore"
                  :disabled="disabled"
                />
                <span class="checkboxTitle">赠送积分</span>
                <div v-if="fillRuleForm.largessItems[clickItem].isLargessScore">
                  <el-form-item
                    label-width="0px"
                    :prop="
                      fillRuleForm.largessItems[clickItem].isLargessScore
                        ? `largessItems[${clickItem}].largessScore`
                        : ''
                    "
                    :rules="rules[`largessItems.largessScore`]"
                  >
                    <el-input
                      :disabled="disabled"
                      class="fillRuleinputMoney"
                      v-model="fillRuleForm.largessItems[clickItem].largessScore"
                      size="mini"
                      @input="
                        limitInputlength(
                          fillRuleForm.largessItems[clickItem].largessScore,
                          fillRuleForm.largessItems[clickItem],
                          'largessScore',
                          true
                        )
                      "
                      :max="9999999"
                    ></el-input>
                    <span>积分</span>
                  </el-form-item>
                </div>
              </div>
            </div>
          </div>
        </template>
      </cardTitleCom>
      <cardTitleCom cardTitle="适用范围">
        <template slot="cardContent">
          <div class="bodyRange">
            <div class="rangeTime marB10">
              <span>适用时间</span>
              <el-radio-group
                v-model="fillRuleForm.useTimeRangeType"
                :disabled="disabled"
              >
                <el-radio
                  v-for="dict in dict.type.use_time_range_type"
                  :key="dict.value"
                  :label="dict.value"
                  >{{ dict.label }}</el-radio
                >
              </el-radio-group>
              <div v-if="fillRuleForm.useTimeRangeType === '2'">
                <el-button
                  class="marT10 marB10"
                  size="mini"
                  @click="handleEvent('openTimeDateDialog')"
                  :disabled="disabled"
                  >选择时间</el-button
                >
                <!-- 可编辑表格 -->
                <EditTable :options="dateOptions" />
              </div>
            </div>
            <div class="rangeVip marB10">
              <span>适用会员</span>
              <el-radio-group
                v-model="fillRuleForm.vipLevelRangeType"
                :disabled="disabled"
              >
                <el-radio
                  v-for="dict in dict.type.fill_vip_level_range_type"
                  :key="dict.value"
                  :label="dict.value"
                  >{{ dict.label }}</el-radio
                >
              </el-radio-group>
              <div
                v-show="
                  fillRuleForm.vipLevelRangeType == '2' ||
                  fillRuleForm.vipLevelRangeType == '1'
                "
              >
                <el-button
                  size="mini"
                  @click="handleEvent('openVipLevelDialog')"
                  :disabled="disabled"
                  >指定会员等级</el-button
                >
                <!-- 可编辑表格 -->
                <EditTable :options="vipLevelOptions" />
              </div>
            </div>
            <div class="rangeShop marB10">
              <span>适用门店</span>
              <el-radio-group v-model="fillRuleForm.shopRangeType" :disabled="disabled">
                <el-radio
                  v-for="dict in dict.type.fill_shop_range_type"
                  :key="dict.value"
                  :label="dict.value"
                  >{{ dict.label }}</el-radio
                >
              </el-radio-group>
              <div
                class="rangeVip"
                v-show="
                  fillRuleForm.shopRangeType == '2' || fillRuleForm.shopRangeType == '1'
                "
              >
                <el-button
                  size="mini"
                  @click="handleEvent('openShopDialog')"
                  :disabled="disabled"
                  >指定门店资料</el-button
                >
                <!-- 可编辑表格 -->
                <EditTable :options="shopOptions" />
              </div>
            </div>
            <div>
              <span class="marR20">适用渠道</span>
              <el-checkbox v-model="fillRuleForm.isAllowOfflineShop" :disabled="disabled"
                >线下门店</el-checkbox
              >
              <el-checkbox v-model="fillRuleForm.isAllowOnlineShop" :disabled="disabled"
                >线上商城</el-checkbox
              >
            </div>
          </div>
        </template>
      </cardTitleCom>
    </el-form>
    <!-- 弹窗 -->
    <Dialog :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import Dialog from "@/components/Dialog";
import EditTable from "@/components/editTable"; //可编辑表格
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import topOperatingButton from "@/views/components/topOperatingButton"; //顶部按钮
import {
  updateOrderStatus,
  getOrderDetail,
  addOrder,
  updateOrder,
  updateEnable,
} from "@/api/vip/base/fillRule"; //会员规则
import { getBillNo } from "@/api/codeRule"; //会员充值规则号
import { uniqWith } from "lodash"; //去重
import { limitInputlength } from "@/utils";
import { getDateChangeDetail } from "@/utils/public"; //适用时间转换

export default {
  name: "vipRuleDetail",
  dicts: ["fill_vip_level_range_type", "fill_shop_range_type", "use_time_range_type"],
  components: {
    topOperatingButton,
    cardTitleCom,
    EditTable,
    Dialog,
  },
  data() {
    return {
      isAntiShake: false, //防抖
      //时间表格配置
      dateOptions: {
        curListIndex: -1, //当前表格行
        mutiSelect: false, //是否多选
        list: [],
        echoList: [],
        curListIndex: -1, //当前表格下标
        tableCellLabel: "序号",
        tableHeight: undefined, //表格高度
        status: undefined, //单据状态
        isNoAddBtn: true, //不显示表格增加
        hideTotal: true,
        columns: [
          {
            prop: "dateTime",
            label: "时间范围",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "",
            label: "",
            minWidth: 150,
            align: "center",
          },
        ],
      },
      //会员级别表格配置
      vipLevelOptions: {
        curListIndex: -1, //当前表格行
        mutiSelect: false, //是否多选
        list: [],
        curListIndex: -1, //当前表格下标
        tableCellLabel: "序号",
        tableHeight: undefined, //表格高度
        status: undefined, //单据状态
        isNoAddBtn: true, //不显示表格增加
        hideTotal: true,
        columns: [
          {
            prop: "vipLevelNo",
            label: "级别编码",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "vipLevelName",
            label: "级别名称",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "",
            label: "",
            minWidth: 150,
            align: "center",
          },
        ],
      },
      //门店表格配置
      shopOptions: {
        curListIndex: -1, //当前表格行
        mutiSelect: false, //是否多选
        list: [],
        curListIndex: -1, //当前表格下标
        tableCellLabel: "序号",
        tableHeight: undefined, //表格高度
        status: undefined, //单据状态
        isNoAddBtn: true, //不显示表格增加
        hideTotal: true,
        columns: [
          {
            prop: "shopNo",
            label: "门店编码",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "shopName",
            label: "门店名称",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "shopTypeName",
            label: "门店类型",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "",
            label: "",
            minWidth: 150,
            align: "center",
          },
        ],
      },
      //优惠券表格配置
      couponOptions: {
        curListIndex: -1, //当前表格行
        mutiSelect: false, //是否多选
        list: [],
        curListIndex: -1, //当前表格下标
        tableCellLabel: "序号",
        tableHeight: undefined, //表格高度
        status: undefined, //单据状态
        isNoAddBtn: true, //不显示表格增加
        hideTotal: true,
        columns: [
          {
            prop: "couponCaseNo",
            label: "优惠券编号",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "couponCaseName",
            label: "优惠券名称",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "couponEmitQty",
            label: "赠送数量(单个会员)",
            minWidth: 100,
            align: "center",
          },
          {
            prop: "couponEmitModeName",
            label: "赠送方式",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "couponEffectModeName",
            label: "生效方式",
            minWidth: 120,
            align: "center",
          },
        ],
      },
      //商品表格配置
      goodsOptions: {
        curListIndex: -1, //当前表格行
        mutiSelect: false, //是否多选
        list: [],
        curListIndex: -1, //当前表格下标
        tableCellLabel: "序号",
        tableHeight: undefined, //表格高度
        status: undefined, //单据状态
        isNoAddBtn: true, //不显示表格增加
        hideTotal: true,
        billStatus: undefined,
        columns: [
          {
            prop: "goodsNo",
            label: "商品编号",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "goodsName",
            label: "商品名称",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "barcode",
            label: "条码",
            minWidth: 120,
            align: "center",
          },
          {
            type: "localSelect",
            prop: "unitId",
            label: "规格",
            minWidth: 120,
            align: "center",
            option: {
              rowDataKey: "units",
              label: "goodsSpec",
              value: "unitId",
              filterable: false,
              change: (val, row) => {
                // let tableIndex = this.options.tableIndex;
                // this.numUnit(row, tableIndex);
              },
            },
            disabled: false,
          },
          {
            type: "localSelect",
            prop: "unitId",
            label: "单位",
            minWidth: 120,
            align: "center",
            option: {
              rowDataKey: "units",
              label: "unitName",
              value: "unitId",
              filterable: false,
              change: (val, row) => {
                // let tableIndex = this.options.tableIndex;
                // this.numUnit(row, tableIndex);
              },
            },
            disabled: false,
          },
          {
            prop: "goodsQty",
            label: "数量",
            type: "input",
            minWidth: 150,
            align: "center",
            disabled: false,
            rules: true,
          },
        ],
      },
      //弹窗配置
      dialogOptions: {
        curType: "", //当前类型(门店/会员/优惠券)
        title: "选择会员",
        width: 1250,
        show: false,
        type: "TreeAndTable",
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      Enable: true, //启用或禁用会员充值规则
      showAudit: true, //审核
      disabled: false, //禁用全部按钮
      //标识已审核
      auditOrder: false,
      //标识已关闭
      stopOrder: false,
      //标识未审核
      noAuditOrder: false,
      //标识已删除
      deleteOrder: false,
      //标识已禁用
      disableOrder: false,
      billType: undefined, //会员充值规则类型
      clickItem: 0, //当前焦点下标
      //选择优惠券开关
      OpenCoupon: false,
      OpenShopInfo: false, //选择门店信息开关
      OpenVipLeve: false, //选择会员等级开关
      OpenGoods: false, //选择商品信息开关

      loadingShopInfo: false, //门店资料适用范围表格加载
      loadingGoods: false, //赠送商品表格加载
      loadingVip: false, //会员适用范围表格加载
      loading: false, //加载
      isGiveMoneyInput: false, //赠送金额输入框
      radioGiveMoney: "1", //自定义赠送
      giveScore: false, //赠送积分
      giveCoupon: false, //赠送优惠券
      appointGoods: false, //指定商品
      giveGoods: false, //赠送商品
      giveMoney: false, //赠送金额
      isActive: false, //充值规则列表获取焦点失去焦点
      isIconInfo: false, //是否显示基本信息
      isIconRange: false, //是否显示适用范围
      isIconRule: false, //是否显示充值规则

      fillRuleForm: {
        isAllowOfflineShop: true, //线下门店
        isAllowOnlineShop: true, //线上商城
        datetime: "", //有效时间
        billStatus: "",
        billNo: "",
        isMultiply: undefined,
        largessItems: [
          {
            fillMoney: undefined, //充值金额
            largessMoney: undefined, //赠送金额
            largessGoodsItems: [], //赠送商品
            largessCouponItems: [], //赠送优惠券
            largessScore: undefined, //赠送积分
            isLargessMoney: false, //是否赠送金额
            largessMoneyMode: 0, //赠送金额模式( 0 自定义 1 充多少送多少)
            isLargessGoods: false, //是否赠送商品
            isLargessGoodsSelect: true, //是否指定商品任选 几种
            isLargessCoupon: false, //是否赠送优惠券
            isLargessScore: false, //是否赠送积分
          },
        ], //新增充值金额
        vipLevelItems: [], //会员适用范围
        shopItems: [], //门店适用范围
        vipLevelRangeType: "0", //适用会员
        shopRangeType: "0", //适用门店,
        useTimeRangeType: "0", //适用时间
      },
      //获取单据详情url
      urlDetail: undefined,
      //修改单据url
      urlUpdate: undefined,
      //新增单据url
      urlAdd: undefined,
      //更新单据状态url
      urlBillStatus: undefined,
      //启用停用
      urlEnable: undefined,
      rules: {
        billNo: [
          {
            required: true,
            message: "请到编码规则添加对应编码格式",
            trigger: ["blur", "change"],
          },
        ],
        billRuleName: [
          {
            required: true,
            message: "请输入方案名称",
            trigger: ["blur", "change"],
          },
        ],
        datetime: [
          {
            required: true,
            message: "请选择有效日期",
            trigger: ["blur", "change"],
          },
        ],
        "largessItems.fillMoney": [
          {
            required: true,
            message: "请输入充值金额",
            trigger: ["blur", "change"],
          },
        ],
        "largessItems.largessMoney": [
          {
            required: true,
            message: "请输入赠送金额",
            trigger: ["blur", "change"],
          },
        ],
        "largessItems.largessScore": [
          {
            required: true,
            message: "请输入赠送积分",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  watch: {
    "fillRuleForm.billStatus": {
      handler(val) {
        const disabledColumns = ["2", "3"].includes(val);
        this.vipLevelOptions.status = disabledColumns;
        this.shopOptions.status = disabledColumns;
        this.couponOptions.status = disabledColumns;
        this.disabled = disabledColumns;
      },
      immediate: true,
    },
    "couponOptions.list": {
      handler(val) {
        if (val.length >= 1) {
          this.fillRuleForm.largessItems[this.clickItem].largessCouponItems = [...val];
        }
      },
      deep: true,
    },
    "goodsOptions.list": {
      handler(val) {
        if (val.length >= 1) {
          this.fillRuleForm.largessItems[this.clickItem].largessGoodsItems = [...val];
        }
      },
      deep: true,
    },
    "goodsOptions.billStatus": {
      handler(val) {
        console.log("进来了吗?单据状态", val);
        const disabledColumns = ["2", "3"].includes(val);
        this.goodsOptions.columns[3].disabled = disabledColumns;
        this.goodsOptions.columns[4].disabled = disabledColumns;
        this.goodsOptions.columns[5].disabled = disabledColumns;
        this.goodsOptions.status = disabledColumns;
      },
      immediate: true,
    },
  },
  activated() {
    this.getDetail();
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    //保留小数位
    limitInputlength,
    //获取详情
    async getDetail() {
      this.reset();
      this.billType = 150102;
      this.changeBillType(this.billType);
      if (this.$route.query.type === "Update" && this.$route.query.billId) {
        this.loading = true;
        //获取会员充值规则详情
        const res = await getOrderDetail(this.$route.query.billId, this.urlDetail);
        //会员充值规则赋值
        this.fillRuleForm = res.data;
        //转换优惠券
        this.fillRuleForm.largessItems.forEach((item) => {
          if (item.largessCouponItems.length > 0) {
            item.largessCouponItems.forEach((v, index) => {
              //组装数据
              let assemblyCouponObj = this.assemblyCoupon(v);
              this.$set(item.largessCouponItems, index, {
                ...v,
                ...assemblyCouponObj,
                couponCaseId: v.largessCouponCaseId,
              });
            });
          }
        });
        this.vipLevelOptions.list = this.fillRuleForm.vipLevelItems;
        this.shopOptions.list = this.fillRuleForm.shopItems;
        this.couponOptions.list =
          this.fillRuleForm.largessItems[0].largessCouponItems || [];
        this.goodsOptions.list =
          this.fillRuleForm.largessItems[0].largessGoodsItems || [];
        this.fillRuleForm.datetime = [
          this.fillRuleForm.begTime,
          this.fillRuleForm.endTime,
        ];
        this.dateOptions.echoList = this.fillRuleForm.vipFillRuleSpecialDates || [];
        this.dateOptions.list = await getDateChangeDetail(
          this.fillRuleForm.vipFillRuleSpecialDates || []
        );
        console.log("单据状态", res.data.billStatus);
        this.goodsOptions.billStatus = res.data.billStatus;
        this.loading = false;
      } else {
        this.disabled = false;
        this.getBillNo();
      }
      this.focusRuleList(this.fillRuleForm.largessItems.length - 1);
    },
    async handleEvent(type, row) {
      switch (type) {
        case "openVipLevelDialog":
          //弹窗配置
          this.dialogOptions = {
            curTable: "vipLevelOptions",
            curType: "vipLevelId", //当前类型(会员/门店/优惠券)
            title: "选择会员",
            width: 1250,
            show: true,
            type: "TreeAndTable",
            formData: this.$dialog({ key: "vipLevel" }),
          };
          break;
        case "openShopDialog":
          //弹窗配置
          this.dialogOptions = {
            curTable: "shopOptions",
            curType: "shopId", //当前类型(会员/门店/优惠券)
            title: "选择门店",
            width: 1250,
            show: true,
            type: "TreeAndTable",
            formData: this.$dialog({ key: "shop" }),
          };
          break;
        case "openCouponDialog":
          //弹窗配置
          this.dialogOptions = {
            curTable: "couponOptions",
            curType: "couponCaseId", //当前类型(会员/门店/优惠券)
            title: "选择类别",
            width: 1250,
            show: true,
            type: "TreeAndTable",
            isStep: true, //是否允许下一步(主要用于优惠券)
            // formData: this.$dialog({ key: 'coupon' })
            formData: this.$dialog({
              key: "coupon",
              option: { queryParams: { isStop: 0, isInvalids: [0, 1] } },
            }),
          };
          this.dialogOptions.formData.table.radioSelect = true;
          this.dialogOptions.formData.table.mutiSelect = false;
          break;
        case "openGoodsDialog":
          //弹窗配置
          this.dialogOptions = {
            curTable: "goodsOptions",
            curType: "goodsId", //当前类型(会员/门店/优惠券)
            title: "选择商品",
            width: 1250,
            show: true,
            type: "TreeAndTable",
            formData: this.$dialog({
              key: "goods",
            }),
          };
          break;
        case "openTimeDateDialog":
          //弹窗配置
          this.dialogOptions = {
            curTable: "dateOptions",
            curType: "timeDate",
            title: "选择时间日期",
            width: 600,
            show: true,
            type: "TimeDateSelect",
            formData: {
              DateTimeList: this.dateOptions.echoList, // 日期
            },
          };
          break;
        case "dialogChange":
          let list = [];
          let curTable = this.dialogOptions.curTable;
          let curType = this.dialogOptions.curType;

          if (curType != "timeDate") {
            if (this.dialogOptions.formData.table.check.length <= 0) return;
            list = this.dialogOptions.formData.table.check;
            list = [...list, ...this[curTable].list];
            list = uniqWith(list, (x, y) => x[curType] == y[curType]);
            if (curType == "couponCaseId")
              list = list.map((item) => ({
                ...item,
                largessCouponCaseId: item.couponCaseId,
              }));
          } else {
            this[curTable].echoList = this.dialogOptions.formData.DateTimeList; //方便适用时间回显
            list = await getDateChangeDetail(
              this.dialogOptions.formData.DateTimeList || []
            );
          }

          this[curTable].list = list;
          break;
        default:
          break;
      }
    },
    //启用停用
    async getEnable(value) {
      const num = value == "启用" ? 0 : 1;
      const obj = {
        billIds: [this.fillRuleForm.billId],
        isStop: num,
      };
      try {
        await updateEnable(obj, this.urlEnable);
        this.$message.success(`${value}成功`);
      } catch (error) {}
    },
    //接口地址
    changeBillType(billType) {
      if (billType === 150102) {
        //会员充值规则
        this.urlAdd = "/api/system/vip/base/fill/rule/save";
        this.urlBillStatus = "/api/system/vip/base/fill/rule/updateStatus";
        this.urlDetail = "/api/system/vip/base/fill/rule/getDetail";
        this.urlUpdate = "/api/system/vip/base/fill/rule/update";
        this.urlEnable = "/api/system/vip/base/fill/rule/isStop";
      }
    },

    //审核会员充值规则方法
    async auditBill() {
      if (["2", "3"].includes(this.fillRuleForm.billStatus)) {
        return this.$message.warning("请操作未审核的方案");
      }
      try {
        if (this.fillRuleForm.billStatus == "0") {
          const obj = {
            billIds: [this.fillRuleForm.billId],
            billStatus: 2,
            billType: this.billType,
          };
          //发送审核api
          const res = await updateOrderStatus(obj, this.urlBillStatus);
          this.fillRuleForm.billStatus = res.data.billStatus;
          this.$message.success("审核成功");
        } else {
          this.$message.warning("请操作未审核的方案");
        }
      } catch {}
    },

    /** 提交按钮 */
    async submitForm(isAdd) {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          this.fillRuleForm = {
            ...this.fillRuleForm,
            vipLevelItems: this.vipLevelOptions.list,
            shopItems: this.shopOptions.list,
            vipFillRuleSpecialDates: this.dateOptions.list,
            begTime: this.fillRuleForm.datetime[0],
            endTime: this.fillRuleForm.datetime[1],
          };
          if (
            this.fillRuleForm.vipLevelRangeType != "0" &&
            this.fillRuleForm.vipLevelItems.length <= 0
          ) {
            return this.$message.error("请选择适用会员");
          }
          if (
            this.fillRuleForm.shopRangeType != "0" &&
            this.fillRuleForm.shopItems.length <= 0
          ) {
            return this.$message.error("请选择适用门店");
          }
          if (
            this.fillRuleForm.useTimeRangeType != "0" &&
            this.fillRuleForm.vipFillRuleSpecialDates.length <= 0
          ) {
            return this.$message.error("请选择适用时间");
          }
          this.loading = true;
          if (this.fillRuleForm.billId) {
            try {
              const res = await updateOrder(this.fillRuleForm, this.urlUpdate);
              this.$message.success("保存成功");
              if (isAdd) {
                this.reset();
                this.getBillNo();
              } else {
                this.fillRuleForm.billStatus = res.data.billStatus;
                this.goodsOptions.billStatus = res.data.billStatus;
              }
            } catch (err) {}
          } else {
            try {
              const res = await addOrder(this.fillRuleForm, this.urlAdd);
              this.$message.success("保存成功");
              if (isAdd) {
                this.reset();
                this.getBillNo();
              } else {
                this.fillRuleForm.billStatus = res.data.billStatus;
                this.fillRuleForm.billId = res.data.billId;
                this.goodsOptions.billStatus = res.data.billStatus;
              }
            } catch (err) {}
          }
          this.loading = false;
        } else {
          this.$message.error("请把带有红星的必填项输入完整!");
        }
      });
    },
    // 校验当前规则
    checkCurRule() {
      // 校验
      if (!this.fillRuleForm.largessItems[this.clickItem].fillMoney) {
        this.$message.warning("请输入充值金额");
        return false;
      }
      if (
        this.fillRuleForm.largessItems[this.clickItem].isLargessGoods &&
        !this.goodsOptions.list.every((item) => item.goodsQty)
      ) {
        this.$message.warning("请输入赠送商品的数量");
        return false;
      }
      return true;
    },
    //新增充值规则
    getRuleListAdd() {
      if (this.disabled) return;
      if (!this.checkCurRule()) return;
      this.fillRuleForm.largessItems[
        this.clickItem
      ].largessGoodsItems = this.goodsOptions.list;
      this.fillRuleForm.largessItems.push({
        fillMoney: 0,
        largessMoney: undefined, //赠送金额
        largessGoodsItems: [], //赠送商品
        largessCouponItems: [], //赠送优惠券
        largessGoodsItems: [], //赠送商品
        isLargessGoods: false, // 是否赠送商品
        largessScore: undefined, //赠送积分
        isLargessMoney: false, //是否赠送金额
        largessMoneyMode: 0, //赠送金额模式( 0 自定义 1 充多少送多少)
        isLargessGoods: false, //是否赠送商品
        isLargessGoodsSelect: true, //是否指定商品任选 几种
        isLargessCoupon: false, //是否赠送优惠券
        isLargessScore: false, //是否赠送积分
      });
      this.focusRuleList(this.fillRuleForm.largessItems.length - 1);
    },
    //删除充值规则某个对象
    deleteRuleList(index) {
      if (this.disabled) return;
      if (this.fillRuleForm.largessItems.length > 1) {
        this.fillRuleForm.largessItems.splice(index, 1);
      } else {
        this.fillRuleForm.largessItems = [
          {
            fillMoney: undefined, //充值金额
            largessMoney: undefined, //赠送金额
            largessScore: undefined, //赠送积分
          },
        ]; //新增充值金额
      }
    },
    //充值规则列表获取焦点
    focusRuleList(index) {
      this.clickItem = index;
      this.couponOptions.list =
        this.fillRuleForm.largessItems[index].largessCouponItems || [];
      this.goodsOptions.list =
        this.fillRuleForm.largessItems[index].largessGoodsItems || [];
    },
    //组装优惠券数据
    assemblyCoupon(value) {
      //发放方式
      const couponEmitModes = {
        0: "即时发放",
        1: "按天分期发放",
        2: "按周分期发放",
        3: "按月分期发放",
      };
      const {
        couponEmitMode,
        couponEveryEmitQty,
        couponEmitTimes,
        couponEmitInterval,
      } = value;
      let couponEmitModeName = couponEmitModes[couponEmitMode];
      if ([1, 2, 3].includes(couponEmitMode)) {
        const timeUnit = couponEmitMode === 1 ? "天" : couponEmitMode === 2 ? "周" : "月";
        couponEmitModeName += `,每次发放数量：${couponEveryEmitQty},分期次数：${couponEmitTimes},每次间隔：${couponEmitInterval}${timeUnit}`;
      }
      //生效方式
      const couponEffectModes = {
        0: "即时生效， ",
        1: "延迟生效， ",
        2: "固定日期生效， ",
      };
      const {
        couponEffectMode,
        couponEffectDays,
        couponEffectDate,
        couponValidDays,
      } = value;
      let couponEffectModeName = couponEffectModes[couponEffectMode];
      if (couponEffectMode === 0) {
        couponEffectModeName += `生效后有效时长${couponValidDays}天`;
      } else if (couponEffectMode === 1) {
        couponEffectModeName += `领取${couponEffectDays}天后生效,生效后有效时长${couponValidDays}天`;
      } else if (couponEffectMode === 2) {
        couponEffectModeName += `${couponEffectDate},生效后有效时长${couponValidDays}天`;
      }
      value.couponEmitModeName = couponEmitModeName;
      value.couponEffectModeName = couponEffectModeName;
      return value;
    },
    // 页面重置
    reset() {
      this.fillRuleForm = {
        isAllowOfflineShop: true, //线下门店
        isAllowOnlineShop: true, //线上商城
        billStatus: "",
        billNo: "",
        isMultiply: undefined,
        datetime: undefined,

        largessItems: [
          {
            fillMoney: undefined, //充值金额
            largessMoney: undefined, //赠送金额
            largessGoodsItems: [], //赠送商品
            largessCouponItems: [], //赠送优惠券
            largessScore: undefined, //赠送积分
            isLargessMoney: false, //是否赠送金额
            largessMoneyMode: 0, //赠送金额模式( 0 自定义 1 充多少送多少)
            isLargessGoods: false, //是否赠送商品
            isLargessGoodsSelect: true, //是否指定商品任选 几种
            isLargessCoupon: false, //是否赠送优惠券
            isLargessScore: false, //是否赠送积分
          },
        ], //新增充值金额
        vipLevelItems: [], //会员适用范围
        shopItems: [], //门店适用范围
        vipLevelRangeType: "0", //适用会员
        shopRangeType: "0", //适用门店
        useTimeRangeType: "0", //适用时间
      };
      this.vipLevelOptions.list = [];
      this.shopOptions.list = [];
      this.couponOptions.list = [];
      this.dateOptions.list = [];
      this.dateOptions.echoList = [];
      this.resetForm("form");
    },
    //获取会员充值规则编号
    async getBillNo() {
      this.loading = true;
      const res = await getBillNo(this.billType);
      this.$set(this.fillRuleForm, "billNo", res);
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
//整体盒子
.wrap {
  padding: 40px 10px 10px 10px;
  background-color: #eaeaea;
  height: 100%;
  min-height: calc(100vh - 84px);
}

//充值规则
.giveRule {
  padding: 0 20px;
  .multiplyTitle {
    padding: 20px 0 10px 0;
    .checkboxTitle {
      margin-left: 7px;
      font-size: 14px;
    }
  }
  //充值规则列表
  .fillRuleListTable {
    display: flex;
    cursor: pointer;
    .fillRuleList {
      padding: 7px;
      width: 200px;
      height: 120px;
      font-size: 14px;
      margin-right: 24px;
      visibility: visible;
      border: 1px solid #e6e6e6;
      .fillRuleListAdd {
        color: #2386ee;
        display: flex;
        flex-direction: row;
        line-height: 110px;
        align-items: center;
        justify-content: center;
      }
      //删除图标
      .fillRuleListMoney {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    //充值规则列表获得焦点
    .focusfillRuleList {
      position: relative;
      padding: 7px;
      width: 200px;
      height: 120px;
      font-size: 14px;
      margin-right: 24px;
      visibility: visible;
      border: 1px solid #017ee1;

      &::before {
        position: absolute;
        content: "";
        right: 0;
        bottom: 0;
        border-right: 13px solid #2386ee;
        border-left: 13px solid transparent;
        border-top: 11px solid transparent;
        border-bottom: 11px solid #2386ee;
      }
      &::after {
        position: absolute;
        content: "";
        width: 10px;
        height: 6px;
        background-color: transparent;
        right: 3px;
        bottom: 5px;
        border-left: 2px solid #fff;
        border-bottom: 2px solid #fff;
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
      .fillRuleListMoney {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  //充值规则详情
  .RuleListdetail {
    display: flex;
    align-items: center;
    margin: 20px 0;
    height: 28px;
    ::v-deep .el-form-item {
      margin-bottom: 0;
    }
    //指定商品
    .appointGoods {
      height: 28px;
      line-height: 28px;
      margin-left: 20px;
    }
    //赠送金额多选框
    .giveMoneyRadio {
      margin: 0 20px;
    }
    //多选框
    .el-checkbox {
      margin-right: 0;
    }
    span {
      font-size: 14px;
    }
    //充值规则详情
    .fillRuleinput {
      width: 150px;
      margin-left: 20px;
    }
    //赠送金额
    .fillRuleinputMoney {
      width: 150px;
      margin-right: 10px;
      margin-left: 10px;
    }

    //详情标题
    .checkboxTitle {
      margin-left: 7px;
      margin-right: 5px;
    }
  }
}
//适用范围
.bodyRange {
  padding: 20px;

  //适用会员
  .rangeVip {
    .el-button {
      margin: 10px 0;
    }
  }
  span {
    font-size: 14px;
    margin-right: 40px;
  }
}
</style>
