<template>
  <div class="wrap" v-loading="loading" id="wrap" ref="wrap">
    <el-form ref="form" :model="options" :rules="rules" label-width="106px">
      <topOperatingButton
        :isExamineBtn="true"
        :showAudit="false"
        :isStopBtn="false"
        id="topOperatingButton"
        ref="topOperatingButton"
        @submitForm="submitForm(false)"
        @addBill="submitForm(true)"
        @auditBill="auditForm"
        @getQuit="$router.go(-1)"
      >
        <template v-slot:specialDiyBtn>
          <el-button
            size="mini"
            :disabled="!options.billId"
            @click="handleEvent('exportFile')"
          >
            导出
          </el-button>

          <el-button size="mini" @click="handleEvent('batchUpdate')">
            批量修改
          </el-button>
          <el-button size="mini" @click="batchDel"> 批量删除 </el-button>
        </template>
      </topOperatingButton>
      <cardTitleCom
        cardTitle="基本信息"
        id="basicInformation"
        ref="basicInformation"
        :billStatus="options.billStatus"
      >
        <template slot="cardContent">
          <div class="x-f marT10">
            <el-form-item label="方案编号" required>
              <el-input
                class="inputWidth"
                size="mini"
                :disabled="true"
                v-model="options.billNo"
                placeholder="方案编号"
              ></el-input>
            </el-form-item>
            <el-form-item label="单据日期" required>
              <el-date-picker
                class="inputWidth"
                size="mini"
                v-model="options.billDate"
                type="date"
                placeholder="单据日期"
                :disabled="['2', '3'].includes(options.billStatus)"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="方案备注" prop="billRemark">
              <el-input
                size="mini"
                class="inputWidth"
                v-model="options.billRemark"
                type="textarea"
                placeholder="备注长度介于 1 和 80 字符之间"
                maxlength="80"
                :autosize="{ minRows: 1, maxRows: 1 }"
                :disabled="['2', '3'].includes(options.billStatus)"
              />
            </el-form-item>
          </div>
          <div class="x-f">
            <el-form-item label="默认充值金额" prop="fillMoney">
              <el-input
                size="mini"
                class="inputWidth"
                v-model="options.fillMoney"
                type="text"
                placeholder="默认充值金额"
                @input="
                  limitInputlength(options.fillMoney, options, 'fillMoney', true, 8, true)
                "
                :disabled="['2', '3'].includes(options.billStatus)"
              ></el-input>
            </el-form-item>
            <el-form-item label="默认赠送金额">
              <el-input
                size="mini"
                class="inputWidth"
                v-model.number="options.presentMoney"
                type="text"
                placeholder="默认赠送金额"
                @input="
                  limitInputlength(
                    options.presentMoney,
                    options,
                    'presentMoney',
                    true,
                    8,
                    true
                  )
                "
                :disabled="['2', '3'].includes(options.billStatus)"
              ></el-input>
            </el-form-item>
            <el-form-item label="收款方式">
              <SelectLocal
                v-model="options.payModeId"
                style="width: 250px"
                :option="{ data: payModeList, value: 'payModeId', label: 'payModeName' }"
                :disabled="['2', '3'].includes(options.billStatus)"
              />
            </el-form-item>
            <el-form-item label="实付金额" prop="factMoney">
              <span>{{ factMoney }}</span>
            </el-form-item>
          </div>
        </template>
      </cardTitleCom>
      <cardTitleCom cardTitle="会员充值列表" class="vipFillTable">
        <template slot="cardContent">
          <div class="table">
            <!-- 可编辑表格 -->
            <!-- <EditTable :options="options" @handleEvent="handleEvent" /> -->
            <keyBoardTable
              ref="myTable"
              :key="tableKey"
              :showFooter="false"
              :isDisabled="this.options.disabled"
              :tableHeight="this.options.tableHeight"
              :isShowCheckbox="true"
              :isTableContentDisabled="isTableContentDisabled"
              :tableColumns="originColumns"
              :tableColumnsCopy="originColumns"
              @handleEvent="handleEvent"
              @handleSelectionChange="handleSelectionChange"
              @handleTableInput="handleTableInput"
              @handleSyncTableData="handleSyncTableData"
              @handleTableCallback="handleTableCallback"
            ></keyBoardTable>

            <div class="left-view">
              <template>
                <span>共</span>
                <span class="num" style="color: #333">{{
                  (options.list && options.list.length) || 0
                }}</span>
                <span>条</span>
                <template v-if="options.mutiSelect">
                  <span>，已选</span>
                  <span class="num">{{ options.check.length }}</span>
                  <span>条</span>
                </template>
              </template>
            </div>
          </div>
        </template>
      </cardTitleCom>
    </el-form>
    <!-- 弹窗 -->
    <Dialog ref="tablePage" :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import SelectLocal from "@/components/tablePage/select/select-local/index.vue";
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import EditTable from "@/components/editTable"; //可编辑表格
import keyBoardTable from "@/components/tablePage/keyBoardTable/index.vue";
import Dialog from "@/components/Dialog";
import topOperatingButton from "@/views/components/topOperatingButton"; //顶部按钮
import {
  vipFillSave,
  vipFillUpdate,
  vipFillUpdateStatus,
  vipFillGetDetail,
} from "@/api/vip/bill/fill";
import { getBillNo } from "@/api/codeRule";
import { uniqWith } from "lodash"; //去重
import { limitInputlength, fcount } from "@/utils";
import { getFormattedDate } from "@/utils/newDate";
import { getTimeDate } from "@/utils/newDate/index.js"; //年月日时分秒
import { arrayObjectCopy } from "@/utils";
import { shopBasPayList } from "@/api/O2OMall/orderManage/order.js";

export default {
  name: "FillDetail",
  components: {
    topOperatingButton,
    //EditTable,
    keyBoardTable,
    cardTitleCom,
    Dialog,
    SelectLocal,
  },
  watch: {
    "options.billStatus": {
      handler(val) {
        this.isTableContentDisabled = false;
        const disabledColumns = ["2", "3"].includes(val);
        this.$refs.myTable?.handleCallbackData([]);
        this.originColumns.forEach((item) => {
          item.disabled = disabledColumns;
        });
        this.$refs.myTable?.handleCallbackData(this.options.list);
        this.options.disabled = disabledColumns;
        if (val) {
          this.isTableContentDisabled = disabledColumns;
        }
        console.log(this.isTableContentDisabled, "this.isTableContentDisabled");
        // this.options.columns[0].disabled = disabledColumns;
        // this.options.columns[7].disabled = disabledColumns;
        // this.options.columns[8].disabled = disabledColumns;
        // this.options.columns[10].disabled = disabledColumns;
        // this.options.status = disabledColumns;
      },
      immediate: true,
    },
    vipNoOption: {
      handler(val) {
        this.originColumns.forEach((item) => {
          if (item.fieldName == "vipNo") {
            item.options = val;
          }
        });
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      loading: false, //遮罩层
      vipNoOption: this.$select({
        key: "vip",
        option: {
          option: {
            label: "vipNo",
            value: "vipNo",
            modal: "vipNo",
            change: (propValue, row) => {
              console.log(propValue, row);
              this.vipNoOption.showItem = [row];
              this.handleChooseVipNo(propValue, row);
            },
            tableChange: (propValue, row) => {
              this.handleEvent("getSelectData", row);
            },
            buttons: [
              {
                type: "more",
                option: {
                  title: "选择会员",
                  width: 1250,
                  type: "TreeAndTable",
                  formData: {
                    ...this.$dialog({ key: "vip" }),
                    table: {
                      ...this.$dialog({ key: "vip" }).table,
                      mutiSelect: true,
                    },
                  },
                },
              },
            ],
          },
        },
      }).option,
      //表格配置
      options: {
        fillMoney: "", // 充值金额
        presentMoney: "", // 赠送金额
        factMoney: "", // 实付金额
        billRemark: "", // 方案备注
        billDate: "", // 方案日期（当前日期）
        billNo: undefined, // 方案编号
        billId: undefined, //方案id
        billStatus: undefined,
        payModeId: 1,
        disabled: false,
        curListIndex: -1, //当前表格行
        mutiSelect: true, //是否多选
        list: [{}],
        check: [], //选中的数据
        curListIndex: -1, //当前表格下标
        tableCellLabel: "序号",
        tableHeight: "", //表格高度
        status: undefined, //方案状态
        retainColumn: true, //保留表格最后一行
        tableIndex: 0,
        columns: [
          {
            prop: "vipNo",
            label: "会员卡号",
            type: "remoteSelect",
            click: "clickVip",
            minWidth: 200,
            align: "center",
            disabled: false,
            rules: true,
          },
          {
            prop: "vipName",
            label: "会员名称",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "vipLevelNo",
            label: "级别编码",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "vipLevelName",
            label: "级别名称",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "tel",
            label: "手机",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "cardStatusName",
            label: "状态",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "totalUsableMoney",
            label: "总余额",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "fillMoney",
            label: "充值金额",
            minWidth: 120,
            align: "center",
            type: "input",
            input: "sumBalance",
            disabled: false,
            rules: true,
            isMinus: true,
          },
          {
            prop: "presentMoney",
            label: "赠送金额",
            minWidth: 120,
            align: "center",
            type: "input",
            input: "sumBalance",
            disabled: false,
            isMinus: true,
          },
          {
            prop: "allFillMoney",
            label: "充值后总余额",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "remark",
            label: "备注",
            minWidth: 120,
            align: "center",
            type: "input",
            disabled: false,
            notVerify: true,
          },
        ],
        summary: ["fillMoney", "presentMoney", "allFillMoney"],
      },
      originColumns: [
        {
          fieldCaption: "会员卡号",
          fieldCustomCaption: "会员卡号",
          fieldName: "vipNo",
          fieldCustomWidth: 200,
          fieldIsDisplay: true,
          type: "selectRemote",
          isRequired: true,
        },
        {
          fieldCaption: "会员名称",
          fieldCustomCaption: "会员名称",
          fieldName: "vipName",
          fieldCustomWidth: 150,
          fieldIsDisplay: true,
        },
        {
          fieldCaption: "级别编码",
          fieldCustomCaption: "级别编码",
          fieldName: "vipLevelNo",
          fieldCustomWidth: 120,
          fieldIsDisplay: true,
        },
        {
          fieldCaption: "级别名称",
          fieldCustomCaption: "级别名称",
          fieldName: "vipLevelName",
          fieldCustomWidth: 160,
          fieldIsDisplay: true,
        },
        {
          fieldCaption: "手机",
          fieldCustomCaption: "手机",
          fieldName: "tel",
          fieldCustomWidth: 160,
          fieldIsDisplay: true,
        },
        {
          fieldCaption: "状态",
          fieldCustomCaption: "状态",
          fieldName: "cardStatusName",
          fieldCustomWidth: 160,
          fieldIsDisplay: true,
        },
        {
          fieldCaption: "总余额",
          fieldCustomCaption: "总余额",
          fieldName: "totalUsableMoney",
          fieldCustomWidth: 160,
          fieldIsDisplay: true,
        },
        {
          fieldCaption: "充值金额",
          fieldCustomCaption: "充值金额",
          fieldName: "fillMoney",
          fieldCustomWidth: 160,
          fieldIsDisplay: true,
          type: "inputNumber",
          isRequired: true,
        },
        {
          fieldCaption: "赠送金额",
          fieldCustomCaption: "赠送金额",
          fieldName: "presentMoney",
          fieldCustomWidth: 160,
          fieldIsDisplay: true,
          type: "inputNumber",
        },
        {
          fieldCaption: "充值后总余额",
          fieldCustomCaption: "充值后总余额",
          fieldName: "allFillMoney",
          fieldCustomWidth: 160,
          fieldIsDisplay: true,
        },
        {
          fieldCaption: "备注",
          fieldCustomCaption: "备注",
          fieldName: "remark",
          fieldCustomWidth: 200,
          fieldIsDisplay: true,
          type: "inputText",
        },
      ],
      isTableContentDisabled: false,
      originColumnsCopy: [],
      tableKey: Date.now(),
      payModeList: [],
      //弹窗配置
      dialogOptions: {
        title: "选择会员",
        width: 1250,
        show: false,
        type: "TreeAndTable",
      },
      rules: {
        billNo: [
          {
            required: true,
            message: "请输入方案编号",
            trigger: ["blur", "change"],
          },
        ],
        billDate: [{ required: true, message: "请输入制单日期", trigger: "blur" }],
      },
      ceshi: undefined,
      pageH: 0, //页面高度
      btnsH: 0, //搜索框高度
      basicH: 0, //基础框高度
      paginationH: 50, //分页高度
    };
  },
  activated() {
    this.getDetail();
    this.handleResize(); //自适应高度
  },
  mounted() {
    this.getDetail();
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.addEventListener("resize", this.handleResize);
  },
  created() {
    this.handleResize();
  },
  computed: {
    factMoney() {
      this.options.factMoney =
        this.options.list?.reduce(
          (moeny, j) => (moeny += Number(j?.fillMoney) || 0),
          0
        ) || 0;
      return this.options.factMoney;
    },
  },
  methods: {
    //保留小数位
    limitInputlength,
    //获取详情
    async getDetail() {
      this.options.billId = undefined;
      this.options.billStatus = undefined;
      this.isTableContentDisabled = false;
      try {
        let { rows } = await shopBasPayList({ isUse: true, pageSize: 999 });
        this.payModeList = rows.filter(
          (i) =>
            [1, 10, 8].includes(i.payModeId) || (Number(i.payModeId) > 50 && i.isDisplay)
        );
      } catch (err) {}
      if (this.$route.query.type === "Update" && this.$route.query.billId) {
        this.reset();
        this.loading = true;
        try {
          //获取方案详情
          const { data } = await vipFillGetDetail(this.$route.query.billId);
          this.vipNoOption.showItem = data.billDetailItems;

          //方案赋值
          this.options = {
            ...this.options,
            ...data,
            list: [],
          };
          this.options.list = data.billDetailItems.map((item) => ({
            ...item,
            totalUsableMoney: item.agoAllFillMoney,
          }));
          setTimeout(() => {
            this.$refs.myTable.handleCallbackData(this.options.list);
          }, 500);
        } catch (err) {}
        this.loading = false;
      } else {
        this.reset();
        this.getBillno();
      }
    },
    //自适应获取高度
    async handleResize() {
      setTimeout(() => {
        this.pageH = document.getElementById("wrap")?.clientHeight || 0;
        this.btnsH = document.getElementById("topOperatingButton")?.clientHeight || 0;
        this.basicH = document.getElementById("basicInformation")?.clientHeight || 0;
        this.options.tableHeight =
          this.pageH - this.btnsH - this.basicH - this.paginationH - 68 + "px";

        console.log(this.pageH, this.btnsH, this.basicH, "金");
        console.log("表格高度", this.options.tableHeight);
      }, 10);
      this.originColumnsCopy = arrayObjectCopy(this.originColumns);
    },
    contains(list, row) {
      var i = list.length;
      while (i--) {
        if (list[i] === row) {
          return i;
        }
      }
      return false;
    },
    handleChooseVipNo(propValue, row) {
      if (row && this.options.tableIndex != undefined) {
        const isRepetition = this.options.list.some((item) => item.vipId == row.vipId);
        console.log(isRepetition, "重复了吗", this.options.tableIndex);
        if (!isRepetition) {
          this.options.list[this.options.tableIndex];
          this.$set(this.options.list, this.options.tableIndex, {
            ...row,
            remark: this.options.billRemark || "",
            fillMoney: this.options.fillMoney ? this.options.fillMoney : "",
            presentMoney: this.options.presentMoney ? this.options.presentMoney : "",
            allFillMoney: fcount(
              [row.totalUsableMoney, this.options.fillMoney, this.options.presentMoney],
              "+"
            ),
          });
          console.log(this.options.list, "插入后");
        } else {
          this.options.list[this.options.tableIndex] = {};
          this.$message.error("请选择不同的会员");
        }
      }
    },
    async handleEvent(type, row) {
      //获取元素在数组的下标
      switch (type) {
        case "clickVip":
          //保存当前表格行下标
          this.options.curListIndex = this.contains(this.options.list, row);
          this.dialogOptions.show = true;
          //弹窗配置
          this.dialogOptions = {
            title: "选择会员",
            width: 1250,
            show: true,
            type: "TreeAndTable",
            formData: this.$dialog({ key: "vip" }),
          };
          break;
        case "sumBalance":
          let index = this.contains(this.options.list, row);
          if (row.vipId) {
            //计算充值后总余额
            this.options.list[index].allFillMoney = fcount(
              [
                fcount(
                  [
                    this.options.list[index].totalUsableMoney,
                    this.options.list[index].fillMoney,
                  ],
                  "+"
                ),
                this.options.list[index].presentMoney,
              ],
              "+"
            );
          }
          break;
        case "batchUpdate": //批量修改
          if (["2", "3"].includes(this.options.billStatus)) {
            return this.$message.warning("请操作未审核的方案");
          }
          if (!this.options.check.length) {
            return this.$message.warning("请选择要操作的数据项");
          }
          //弹窗配置
          this.dialogOptions = {
            title: "批量修改充值",
            width: 500,
            show: true,
            click: "getBatchUpdate",
            type: "VipBatchFill",
            data: {},
          };
          break;
        case "getBatchUpdate":
          const { fillMoney, presentMoney } = this.dialogOptions.formData;
          this.options.check.forEach((item) => {
            if (!item.vipId) return;
            this.$set(item, "fillMoney", fillMoney);
            this.$set(item, "presentMoney", presentMoney);
            let allFillMoney = fcount(
              [item.totalUsableMoney, item.fillMoney, item.presentMoney],
              "+"
            );
            this.$set(item, "allFillMoney", allFillMoney);
          });
          break;
        case "getSelectData":
          if (!row) return;
          let { check } = row;
          if (check.length <= 0) return;
          this.vipNoOption.showItem = check;

          let list = check.map((item) => ({
            ...item,
            remark: this.options.billRemark || "",
            fillMoney: this.options.fillMoney ? this.options.fillMoney : "",
            presentMoney: this.options.presentMoney ? this.options.presentMoney : "",
            allFillMoney: fcount(
              [item.totalUsableMoney, this.options.fillMoney, this.options.presentMoney],
              "+"
            ),
          }));
          let tableIndex = this.options.tableIndex;
          this.options.tableIndex = undefined;
          this.options.list.splice(tableIndex, 1, ...list);
          this.options.list = uniqWith(this.options.list, (x, y) => x.vipId == y.vipId);
          break;
        case "tableIndex":
          this.options.tableIndex = row;
          break;
        case "exportFile": // 导出
          this.download(
            "/api/system/vip/bill/vipFill/export",
            {
              billId: this.options.billId,
            },
            `会员充值导出${getTimeDate()}.xlsx`
          );
          break;

        default:
          break;
      }
    },
    handleTableInput(e, scope, item) {
      let row = scope.row;
      if (item.fieldName == "fillMoney" || item.fieldName == "presentMoney") {
        let index = this.contains(this.options.list, row);
        if (row.vipId) {
          //计算充值后总余额
          this.options.list[index].allFillMoney = fcount(
            [
              fcount(
                [
                  this.options.list[index].totalUsableMoney,
                  this.options.list[index].fillMoney,
                ],
                "+"
              ),
              this.options.list[index].presentMoney,
            ],
            "+"
          );
        }
      }
    },
    handleSelectionChange(data) {
      this.options.check = data;
    },
    //表格数据更新回调
    handleSyncTableData(val) {
      this.options.list = val;
      console.log(this.options.list, "更新了饿吗");
    },
    //表格数据验证回调
    async handleTableCallback(data, isAdd) {
      //console.log(data,flag);
      this.loading = true;
      console.log(this.options.billDate, "什么情况");
      let form = {
        billNo: this.options.billNo, // 方案号
        billId: this.options.billId, // 方案ID
        billDate: this.options.billDate,
        // ? this.options.billDate.split(' ')[0]
        // : '', // 方案时间
        payModeId: this.options.payModeId,
        billDetailItems: this.options.list, // 列表
        factMoney: this.options.factMoney,
        fillMoney: this.options.fillMoney,
        billStatus: this.options.billStatus,
        presentMoney: this.options.presentMoney,
        billRemark: this.options.billRemark,
      };
      form.billDetailItems = form.billDetailItems.filter((item) => item.vipId);
      if (form.billId) {
        try {
          const { data } = await vipFillUpdate(form);
          this.options = {
            ...this.options,
            billStatus: data.billStatus,
          };
          this.$message.success("修改成功");
          //新增
          if (isAdd) {
            this.reset();
            this.getBillno();
          }
        } catch (err) {}
        this.loading = false;
      } else {
        try {
          const { data } = await vipFillSave(form);
          this.options = {
            ...this.options,
            billStatus: data.billStatus,
            billId: data.billId,
          };
          this.$message.success("新增成功");
          //新增
          if (isAdd) {
            this.reset();
            this.getBillno();
          }
          this.loading = false;
        } catch (err) {
          this.loading = false;
        }
      }
    },
    // 提交保存
    async submitForm(isAdd) {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          this.$refs.myTable.handleValidateTable(isAdd);
        } else this.$message.error("请把带有红星的必填项输入完整!");
      });
    },
    //初始化数据
    reset() {
      //表格配置
      this.options = {
        ...this.options,
        fillMoney: "", // 充值金额
        presentMoney: "", // 赠送金额
        factMoney: "", // 实付金额
        billRemark: "", // 方案备注
        billDate: "", // 方案日期（当前日期）
        billNo: undefined, // 方案编号
        billId: undefined, //方案id
        curListIndex: -1, //当前表格行
        mutiSelect: true, //是否多选
        payModeId: 1,
        list: [{}],
        check: [], //选中的数据
        curListIndex: -1, //当前表格下标
        tableCellLabel: "序号",
        status: undefined, //方案状态
        retainColumn: true, //保留表格最后一行
        tableIndex: 0,
        billStatus: undefined,
      };
      console.log(this.options, "options");
      if (this.$refs.myTable) {
        this.$refs.myTable.handleCallbackData(this.options.list);
      } else {
        setTimeout(() => {
          this.$refs.myTable.handleCallbackData(this.options.list);
        }, 500);
      }
      //弹窗配置
      this.dialogOptions = {
        title: "选择会员",
        width: 1250,
        show: false,
        type: "TreeAndTable",
      };
      this.resetForm("form");
    },
    // 获取订单编号
    async getBillno() {
      this.options.billNo = await getBillNo(150201);
      this.options.billDate = getFormattedDate(2, "-");
    },
    // 审核/反审核  按钮
    async auditForm() {
      if (this.options.billStatus == "0") {
        this.$confirm(`确定审核该方案`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            this.loading = true;
            try {
              const { data } = await vipFillUpdateStatus({
                billIds: [this.options.billId],
                billStatus: 2,
              });
              this.options = {
                ...this.options,
                billStatus: data.billStatus,
              };
              this.isTableContentDisabled = true;
              // this.originColumns.forEach((item) => {
              //   item.disabled = true;
              // });
              this.$message.success(`审核成功`);
              this.loading = false;
            } catch (err) {
              this.loading = false;
            }
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.$message.warning("请操作未审核的方案");
      }
    },
    //批量删除
    batchDel() {
      if (["2", "3"].includes(this.options.billStatus)) {
        return this.$message.warning("请操作未审核的方案");
      }
      if (!this.options.check.length) {
        return this.$message.warning("请选择要操作的数据项");
      }
      this.$confirm("确定删除选中的数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let ids = [];
          this.options.check.forEach((item) => {
            if (item.vipId) ids.push(item.vipId);
          });
          if (ids.length >= 1) {
            this.options.list = this.options.list.filter(
              (item) => !ids.includes(item.vipId)
            );
            if (!this.options.list || this.options.list.length <= 0)
              this.options.list = [{}];
            this.$refs.myTable.handleCallbackData(this.options.list);
            this.options.check = [];
          } else this.$message.error("暂无可删除数据");
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
//整体盒子
.wrap {
  padding: 40px 10px 10px 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
}
//日期
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 250px;
}
.table {
  padding: 10px;
}
::v-deep .el-form-item--mini.el-form-item {
  margin: 0;
}
.left-view {
  height: 50px;
  line-height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;

  .num {
    color: #1890ff;
    margin: 0 2px;
  }

  .checkAll {
    margin-left: 5px;
    color: #1890ff;
    cursor: pointer;
  }
}
</style>
